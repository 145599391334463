import { EAppraisalLinkType } from '@/utils/constants';
import ClientLayout from '@layouts/ClientLayout';
import Page404 from '@pages/404';
import ClientAppraisal from '@pages/client/appraisal';
import ClientAppraisalFail from '@pages/client/appraisal/Fail';
import ClientWelcome from '@pages/client/welcome';

export default [
    {
        path: '/',
        element: <ClientLayout linkType={EAppraisalLinkType.yeastar} />,
        children: [
            {
                // 欢迎页
                path: 'welcome',
                element: <ClientWelcome />,
            },
            {
                // 答题页
                path: 'appraisal',
                element: <ClientAppraisal />,
            },
            {
                // 答题失败页
                path: 'appraisal_fail',
                element: <ClientAppraisalFail />,
            },
            {
                path: '',
                element: <Page404 />,
            },
            {
                path: '*',
                element: <Page404 />,
            },
        ],
    },
];
